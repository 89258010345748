import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../service/login.service';
import { ApiListService } from '../service/apiList.service';
import { CommanHelperService } from '../service/commanHelper.service';
import { NotificationsService } from 'angular2-notifications';
import { CookieService } from 'ngx-cookie-service';
import { StatesService } from '../service/states.service'
import { HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicePageService } from '../service/servicePage.service';
import { Input, Output, AfterContentInit, ContentChild, AfterViewInit, ViewChild, ViewChildren } from '@angular/core';
import { Ng2DeviceService } from 'ng2-device-detector';
import { FlavourDomainService } from '../service/flavour-domain.service';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { WindowService } from '../service/windo.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../service/language.service';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',

})
export class LoginComponent implements OnInit, AfterViewInit {

  token
  token2
  isIos: boolean = false;
  isAndroid: boolean = false;

  constructor(private router: Router, private api: ApiListService, private loginServer: LoginService, private apiList: ApiListService, private cookie: CookieService, private spinner: NgxSpinnerService, private deviceService: Ng2DeviceService,
    private _service: NotificationsService,
    private servicePageService: ServicePageService, private commanHelper: CommanHelperService,
    public states: StatesService, private route: ActivatedRoute, private FlavourDomain: FlavourDomainService,
    private languageService: LanguageService,
    private win: WindowService,
    private translate: TranslateService
  ) {
    this.commanHelper.progressbarStart();
    this.token = this.cookie.get("token")
    this.token2 = this.cookie.get("token")
    if (this.token) {
      this.router.navigate(['/home']);
    }

    this.states.userLogin.username = null;
    this.states.userLogin.password = null;

    setTimeout(() => {
      this.checkOperatingSystem();
    }, 1000);
  }
  // switchLanguage(lang: string) {
  //   this.translate.use(lang); // Change language dynamically
  // }



  options = this.commanHelper.getNotificationOptionObj();
  hide = true;
  loginError;
  public secondParam
  windowRef: any;
  lang: any;
  ngOnInit() {
    this.commanHelper.progressbarEnd();
    this.secondParam = this.route.snapshot.queryParamMap.get('is_email_verify');
    JSON.stringify(this.deviceService.getDeviceInfo())
    this.cookie.set('deviceInfo', JSON.stringify(this.deviceService.getDeviceInfo()))

    // Get the language from the URL parameter
    this.route.queryParams.subscribe((params) => {
      this.lang = params['lang'];
      if (!this.lang || (this.lang !== 'en' && this.lang !== 'es')) {
        this.languageService.redirectToDefaultLang('en');
        return;
      }
      this.translate.setDefaultLang(this.lang);
      this.translate.use(this.lang);
    });

  }
  registerNow() {
    this.router.navigate(['/registration'], { queryParams: { lang: this.lang } });
  }

  public checkOperatingSystem() {
    var useragent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(useragent)) {
      document.getElementById('mobile').style.display = "block";
      document.getElementById('test1').style.height = "90%";
      this.isAndroid = true;
    }
    if (/iPad|iPhone|iPod/.test(useragent)) {
      document.getElementById('mobile').style.display = "block";
      document.getElementById('test1').style.height = "90%";
      this.isIos = true;
    }
  }

  notNOw() {
    document.getElementById('mobile').style.display = "none";
    document.getElementById('test1').style.height = "100%";
    this.isAndroid = false;
    this.isIos = false;
  }

  switcToApp() {
    if (this.FlavourDomain.domain().key == "allyhealth") {
      if (this.isAndroid) {
        window.location.href = 'https://allyhealthapp.page.link/openandroidapp'
      }
      if (this.isIos) {
        window.location.href = "https://dynamic.allyhealth.app/testing"
      }
    }
    if (this.FlavourDomain.domain().key == "BN") {
      if (this.isAndroid) {
        window.location.href = 'https://benefitsnavigator.page.link/openandroidapp'
      }
      if (this.isIos) {
        window.location.href = "https://benefitsnavigator.page.link/openandroidapp"
      }
    }

  }

  @ViewChild('email') private elementRef: ElementRef;

  public ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
  }

  public userLoginModel = { "username": "", "password": "" }
  public modelData = { "username": "", "password": "" }
  footerTab: any;
  modalOpen: any = false
  userData: any
  phoneNumber: any
  userEmail: any
  isModal: any = false;
  loginFirst(model) {
    this.loginError = '';
    this.spinner.show();
    model.username = model.username.trim()
    Object.assign(this.userLoginModel, model);
    Object.assign(this.modelData, model);
    if (this.userLoginModel.password != null) {
      this.userLoginModel.password = btoa(this.userLoginModel.password);
    }
    var url = this.apiList.verifyUser
    this.loginServer.login(url, this.userLoginModel).subscribe(
      sucesses => {

        if (sucesses.is_2fa_enable) {
          this.isModal = true

          if (this.modalOpen) {
            $('body').addClass('modal-open');
            $('<div class="modal-backdrop fade show"></div>').appendTo('body');
          }
          var modal = document.getElementById('exampleModalCenter');
          modal.classList.add('show');
          modal.style.display = 'block';
          if (!firebase.apps.length) {
            firebase.initializeApp(environment.firebase);
          }
          this.windowRef = this.win.windowRef
          this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container", {
            "size": "invisible",
          }
          );
          this.windowRef.recaptchaVerifier.render()
            .then(widgetId => {
              this.windowRef.recaptchaWidgetId = widgetId;
            });

          this.userData = sucesses
          this.userData.phone = this.userData.phone.replace(/\D/g, '');
          if (this.userData.phone != null) {
            let firstThreeDigits = this.userData.phone.substring(0, 3);
            let middlePart = this.userData.phone.substring(3, 8);
            let lastTwoDigits = this.userData.phone.substring(8);
            this.phoneNumber = "********" + lastTwoDigits;
          }
          if (this.userData.email != null) {
            let parts = this.userData.email.split('@');
            let localPart = parts[0];
            let domainPart = parts[1];
            let maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2);
            this.userEmail = maskedLocalPart + '@' + domainPart;
          }
          this.spinner.hide();
        } else {
          this.isModal = false
          this.login(this.states.userLogin);
          // this.spinner.hide(); 
        }

      },
      error => {
        this.spinner.hide();
        if (error.status == 401) {
          if (JSON.parse(error._body).active_subscriber) {
            this.loginError = JSON.parse(error._body).error;
          } else {
            this._service.error('Error', JSON.parse(error._body).error);
          }
        }
        else {
          this._service.error('Error', JSON.parse(error._body).error);
        }
        this.modalOpen = true
        this.closeModal()
      });
  }
  login(model) {
    this.loginError = '';
    this.spinner.show();
    model.username = model.username.trim()
    Object.assign(this.userLoginModel, model);
    if (this.userLoginModel.password != null) {
      this.userLoginModel.password = btoa(this.userLoginModel.password);
    }

    var url = this.apiList.login

    this.loginServer.loginLanguage(url, this.userLoginModel).subscribe(
      sucesses => {
        this.spinner.show();

        if (sucesses && sucesses.spanish__url) {
          window.location.href = sucesses.spanish__url;
        } else {
          this.commanHelper.progressbarStart();
          var token: any = sucesses;
          this.token2 = sucesses.token;
          this.cookie.set('token', token.token);
          this.cookie.set('primaryToken', token.token);
          this.cookie.set('isParentLogin', sucesses.is_parent_login);
          localStorage.setItem('password', atob(this.userLoginModel.password))
          this.getProfile()
        }
        var url = this.api.cobranding;
      },
      error => {
        this.spinner.hide();
        if (error.status == 401) {
          if (JSON.parse(error._body).active_subscriber) {
            this.loginError = JSON.parse(error._body).error;
          } else {
            this._service.error('Error', JSON.parse(error._body).error);
          }
        }
        else {
          this._service.error('Error', JSON.parse(error._body).error);
        }
        this.modalOpen = true
      });
  }
  closeModal() {
    this.otpPhase = false
    clearInterval(this.interval);
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    var modal = document.getElementById('exampleModalCenter');
    modal.classList.remove('show');
    modal.style.display = 'none';
  }
  initialCountry: 'us'
  countryCodeNum;
  onCountryChange(event: any) {
    this.countryCodeNum = event.dialCode
    this.initialCountry = event.iso2
  }
  phoneBorder: any = false
  emailBorder: any = false
  setBorder(type: any) {
    if (type == 'phone') {
      this.phoneBorder = true
      this.emailBorder = false
    }
    if (type == 'email') {
      this.phoneBorder = false
      this.emailBorder = true
    }
  }
  otpPhase: any = false
  verifyOption: any;

  currentNumCode:any;
  sendAunthenticationCode(type) {
    this.otpPhase = true
    this.verifyOption = type
    if (type == 'Email Address') {
      var url = this.api.verifyEmail
      let data = {
        "username": this.modelData.username,
        "email": this.userData.email
      }
      this.loginServer.login(url, data).subscribe(
        sucesse => {
          const translatedText = this.translate.instant('success');
          this._service.success(translatedText, sucesse.success);
        },
        error => {
        });
    }

    if (type == 'Mobile Number') {
      let num;
      if (this.countryCodeNum) {
        num = "+" + this.countryCodeNum + this.userData.phone;
        this.currentNumCode=this.countryCodeNum
      } else {
        num = "+1" + this.userData.phone;
        this.currentNumCode="1"
      }
      
      this.getOTP(num)
    }

    this.resendTime()
  }
  getOTP(phone: any) {
    const appVerifier = this.windowRef.recaptchaVerifier;
    firebase.auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmationResult) => {
        localStorage.setItem('verificationIdPhone', JSON.stringify(confirmationResult.verificationId));
        this._service.success('Success', 'One time password has been sent successfully');
      }).catch((error) => {
        this._service.error('Error', error.message);
        this.otpPhase = false
      })
    // let data = {
    //   "mobile_number": "+917827134309",
    // }
    // this.spinner.show();
    // var url = this.api.otpSent
    // this.loginServer.login(url, data).subscribe(
    //   success => {
    //     const translatedText = this.translate.instant('success');
    //     const translatedOTPverified = this.translate.instant('OTPsent');
       
    //     this._service.success(translatedText,translatedOTPverified);
    //     this.spinner.hide()
    //   },
    //   error => {
    //     this.otpPhase = false
    //     const translatedError = this.translate.instant('failedOTP');
    //     if(error.status==400){
    //       this._service.error("error", translatedError);
    //     }else{
    //       this._service.error("error", JSON.parse(error._body).detail);
    //     }
    //     this.spinner.hide()
    //   });
  }
  bothOtp: string
  verifyClose: any = false
  onOtpBothChange(otpCode: any) {
    this.bothOtp = otpCode
    if (this.bothOtp.length == 6) {
      this.verifyClose = true
    } else {
      this.verifyClose = false
    }
  }
  resendBothOtp(type) {
    this.resendBtnDisable = false
    this.sendAunthenticationCode(type)
  }
  resendBtnDisable: any = false
  count: any;
  interval: any
  resendTime() {
    this.count = 60
    this.interval = setInterval(() => {
      if (this.count > 0) {
        this.count--;
      } else {
        clearInterval(this.interval);
        this.resendBtnDisable = true;
      }
    }, 1000);


  }
  handleClickBothVerify(type) {
    this.spinner.show()
    if (type == 'Email Address') {
      let data = {
        "username": this.modelData.username,
        "otp": this.bothOtp
      }
      var emailUrl = this.api.verifyOtp
      this.loginServer.login(emailUrl, data).subscribe(
        sucesse => {
          this.closeModal()
          this.login(this.modelData)
        },
        error => {
          if(error.status==400){
            const translatedText = this.translate.instant('invalidOtp');
            this._service.error('Error', translatedText);
          }else{

            this._service.error('Error', JSON.parse(error._body).error);
          }
          this.spinner.hide()
        });
    }

    if (type == 'Mobile Number') {
      this.spinner.show();
      let num;
      if (this.countryCodeNum) {
        num = "+" + this.countryCodeNum + this.userData.phone;
      } else {
        num = "+1" + this.userData.phone;
      }
    //   let data = {
    //     "mobile_number": "+917827134309",
    //     "otp": this.bothOtp
    // }
    //   var url = this.api.otpVerify
    //   this.loginServer.login(url, data).subscribe(
    //     success => {
    //       this.spinner.hide();
    //       const translatedText = this.translate.instant('success');
    //       const translatedOTPverified = this.translate.instant('OTPverified');
    //       this._service.success(translatedText, translatedOTPverified);
    //       this.closeModal()
    //       this.login(this.modelData)
    //     },
    //     error => {
    //       this.spinner.hide();
    //       // this.otpPhase = false
    //       const translatedexpiredOTP = this.translate.instant('expiredOTP');
    //       this._service.error("error", translatedexpiredOTP);
    //     });
      // otpVerify
      try{
       
        var credential = firebase.auth.PhoneAuthProvider.credential(
          JSON.parse(localStorage.getItem('verificationIdPhone') || '{}'),
          this.bothOtp
        );
        firebase
          .auth()
          .signInWithCredential(credential)
          .then((response) => {
            this.closeModal()
            this.login(this.modelData)
  
          })
          .catch((error) => {
            this.spinner.hide();
            const translatedText = this.translate.instant('invalidOtp');
            this._service.error('Error', translatedText);
  
          });
      }catch (error) {
        this.spinner.hide()
        console.error("Credential creation error:", error.message);
        const ThereWasAProblem = this.translate.instant('ThereWasAProblem');
        this._service.error('Error', ThereWasAProblem);
      }
      
    }
  }
  ngOnDestroy() {
    if (this.secondParam != null && this.token2 != null) {
      var params = new HttpParams();
      params = params.set('is_email_verify', '1');

      this.loginServer.emailVerify(params).subscribe(
        res => { },
        error => {
          if (error.status == 401) {
            this._service.error('Error', this.states.sessionExpiredMessage);
            this.servicePageService.extendJwt()
          }
        }
      );
    }
  }
  dependentsToSwitch: any = []
  getProfile() {
    var url = this.api.userProfile;
    this.servicePageService.masterDropDown(url).subscribe(data => {
      localStorage.setItem("lyricData", JSON.stringify(data.primary.lyric_data))
      this.spinner.hide();
      this.getDepentdentToSwitch(data)
      localStorage.setItem("dp23", JSON.stringify(data.primary))
      localStorage.setItem("dependentsToSwitch23", JSON.stringify(this.dependentsToSwitch))
      localStorage.setItem("data23", JSON.stringify(data))
      this.router.navigate([`/home`]);

    },
      error => {
        this.spinner.hide();
        if (error.status == 401) {
          this._service.error('Error', this.states.sessionExpiredMessage);
          this.servicePageService.extendJwt()
        }
      });

  }
  getDepentdentToSwitch(data) {

    this.dependentsToSwitch = []
    let localdata = localStorage.getItem('primaryUser')
    if ((data.dependents == null || data.dependents.length == 0) && (!data.primary.is_primary)) {
      this.dependentsToSwitch.push(data.parent);

    }

    else {
      for (var i = 0; i < data.dependents.length; i++) {
        if (data.dependents[i].is_adult_dependent) {
          if (data.dependents[i].is_registered && (data.dependents[i].permission_allow_to_primary)) {
            this.dependentsToSwitch.push(data.dependents[i]);
          }
        } else if (data.dependents[i].is_registered && !data.dependents[i].is_adult_dependent) {
          this.dependentsToSwitch.push(data.dependents[i]);
        }
      }
    }
  }

}
